<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Declined Assignments
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number green">{{ declinedAssignments.length }}</span>
      </v-card-title>

      <v-card-text>
        Your location(s) have {{ declinedAssignments.length }} declined
        {{ pluralize('assignment', declinedAssignments.length) }}
      </v-card-text>
    </v-card>

    <modal-assignment-list
      ref="declinedAssignments"
      name="Upcoming Assignments"
      :assignments="declinedAssignments"
    ></modal-assignment-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { pluralize, todayString } from '@/util';
import ModalAssignmentList from './ModalAssignmentList.vue';
import Spinner from '@/components/shared/Spinner.vue';
import { ASSIGNMENT_STATUS } from '@/shared/common';

export default {
  components: { ModalAssignmentList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'declinedAssignments') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('assignment', { assignments: 'assignments', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    declinedAssignments() {
      return this.assignments
        .filter((e) => e.status == ASSIGNMENT_STATUS.SELF_DECLINED || e.status == ASSIGNMENT_STATUS.ADMIN_DECLINED)
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    pluralize,
    openWidget() {
      this.setDashboardPanel('declinedAssignments');
      this.$refs.declinedAssignments.dialog = true;
    },
  },
};
</script>
