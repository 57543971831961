var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8 dashboard", attrs: { fluid: "" } },
    [
      _vm.loading
        ? _c(
            "v-row",
            { staticClass: "mb-4" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      color: "primary",
                      absolute: "",
                      indeterminate: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mb-4 mt-2" },
        [
          _c(
            "v-col",
            { staticClass: "d-flex py-0", attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { dark: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createRequest()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v("New Trip "),
                ],
                1
              ),
              _c("AssignmentListMenu"),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "align-baseline" },
        [
          _vm.showNeedAttention
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("NeedAttention")],
                1
              )
            : _vm._e(),
          _vm.showReadyToAssign
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("ReadyToAssign")],
                1
              )
            : _vm._e(),
          _vm.showAwaitingApproval
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("AwaitingApproval")],
                1
              )
            : _vm._e(),
          _vm.showMyTripRequests
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("MyTripRequests")],
                1
              )
            : _vm._e(),
          _vm.showChangesRequested
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("ChangesRequested")],
                1
              )
            : _vm._e(),
          _vm.showOvernightOOS
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("OvernightOOS")],
                1
              )
            : _vm._e(),
          _vm.showUpcomingTrips
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("UpcomingTrips")],
                1
              )
            : _vm._e(),
          _vm.showUpcomingAssignments
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("UpcomingAssignments")],
                1
              )
            : _vm._e(),
          _vm.showRecentlyCompleted
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("RecentlyCompleted")],
                1
              )
            : _vm._e(),
          _vm.showThirdPartyPayment
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("ThirdPartyPayment")],
                1
              )
            : _vm._e(),
          _vm.showHealthConcerns
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("HealthConcerns")],
                1
              )
            : _vm._e(),
          _vm.showApprovedPayments
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("ApprovedPayments")],
                1
              )
            : _vm._e(),
          _vm.showScheduledVehicles
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [_c("ScheduledVehicles")],
                1
              )
            : _vm._e(),
          _vm.showPendingAssignments
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("PendingAssignments")],
                1
              )
            : _vm._e(),
          _vm.showDeclinedAssignments
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("DeclinedAssignments")],
                1
              )
            : _vm._e(),
          _vm.showNutrition
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("Nutrition")],
                1
              )
            : _vm._e(),
          _vm.showPaymentSent
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [_c("PaymentSent")],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }